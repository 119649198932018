<template>
  <div class="price-list">
    <div class="card card-1">
      <div class="title">{{ $t("silver") }}</div>
      <div class="button" @click="$emit('buySilver')">{{ $t("buyNow") }}</div>
      <div
        class="price"
        style="color: maroon; text-decoration: line-through; font-weight: 300;"
      >
        $170
      </div>

      <div class="price">$144.50</div>
      <div class="descs">
        <div class="desc">
          <span class="check1"></span>
          <span class="check2"></span>

          <span style="margin-left: 1em">{{ $t("desc1") }}</span>
        </div>
        <div
          class="desc"
          v-for="(desc, index) in description"
          v-bind:key="index"
        >
          <span class="check1"></span>
          <span class="check2"></span>

          <span style="margin-left: 1em">{{ desc }}</span>
        </div>
      </div>
      <div class="sale">15% {{ $t("off") }} !</div>
    </div>
    <div class="card">
      <div class="title">{{ $t("gold") }}</div>
      <div class="button" @click="$emit('buyGold')">{{ $t("buyNow") }}</div>
      <div
        class="price"
        style="color: maroon; text-decoration: line-through; font-weight: 300;"
      >
        $250
      </div>
      <div class="price">$225</div>
      <div class="descs">
        <div class="desc">
          <span class="check1"></span>
          <span class="check2"></span>

          <span style="margin-left: 1em">{{ $t("desc1a") }}</span>
        </div>
        <div
          class="desc"
          v-for="(desc, index) in description"
          v-bind:key="index"
        >
          <span class="check1"></span>
          <span class="check2"></span>

          <span style="margin-left: 1em">{{ desc }}</span>
        </div>
      </div>
      <div class="sale">10% {{ $t("off") }} !</div>
    </div>
    <div class="card">
      <div class="title">{{ $t("diamond") }}</div>
      <div class="button" @click="$emit('buyDiamond')">{{ $t("buyNow") }}</div>
      <div
        class="price"
        style="color: maroon; text-decoration: line-through; font-weight: 300;"
      >
        $295
      </div>
      <div class="price">$280.25</div>
      <div class="descs">
        <div class="desc">
          <span class="check1"></span>
          <span class="check2"></span>

          <span style="margin-left: 1em">{{ $t("desc1b") }}</span>
        </div>
        <div
          class="desc"
          v-for="(desc, index) in description"
          v-bind:key="index"
        >
          <span class="check1"></span>
          <span class="check2"></span>

          <span style="margin-left: 1em">{{ desc }}</span>
        </div>
      </div>
      <div class="sale">5% {{ $t("off") }} !</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    description() {
      return [
        this.$t("desc2"),
        this.$t("desc3"),
        this.$t("desc4"),
        this.$t("desc5"),
        this.$t("desc6"),
      ];
    },
  },
};
</script>
<style scoped>
.sale {
  position: absolute;
  bottom: 0;
  background-color: #d0a549;
  width: 100%;
  left: 0;
  border-radius: 0 0 0.7em 0.7em;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}
.price-list {
  margin: 0 auto;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
}
.button {
  color: white;
  padding: 0.4em 0.7em;
  background-color: #6200ee;
  font-size: 0.9em;
  width: max-content;
  margin-bottom: 2em;
  border-radius: 2em;
  position: absolute;
  right: 1em;
  top: 3em;
  cursor: pointer;
}
.card {
  position: relative;
  height: 25em;
  width: 17em;
  margin: 1em;
  border-radius: 1em;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white !important;
}
.title {
  color: #64bbbd;
  font-size: 1em !important;
  text-align: center;
  margin-top: 1em;
}
.price {
  font-size: 2em;
  color: #64bbbd;
  text-align: center;
  font-weight: bold;
}
.desc {
  display: flex;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 0.6em;
}
.descs {
  margin: 1em 0.5em 0 1em;
}
.check1 {
  min-width: 2px;
  height: 1em;
  background-color: #d0a549;
  transform: rotate(40deg);
}
.check2 {
  width: 2px;
  height: 0.5em;
  background-color: #d0a549;

  transform: translate(-7px, 6px) rotate(140deg);
}
@media only screen and (max-width: 970px) {
  .descs {
  }
  .price-list {
    flex-direction: column;
    overflow: auto;
    height: 50vh;
    justify-content: center;
    width: 100%;
  }
  .card {
    width: 80vw;
    margin: 2em auto;
  }
  .card-1 {
    margin-top: 45em;
  }
}
</style>

<template>
  <v-app>
    <v-main class="wrapper">
      <div class="logo">
        <img src="../assets/logo.png" />
      </div>
      <div class="form-wrapper">
        <div class="title">
          <p class="step-one" v-if="step === 1">{{ $t("stepOneMsg") }}</p>
        </div>
        <BookingForm
          @stepChanged="step = $event"
          class="form"
          v-if="showForm"
          :priceSelected="priceSelected"
        />
        <Prices
          @buySilver="(showForm = true), (priceSelected = 0)"
          @buyGold="(showForm = true), (priceSelected = 1)"
          @buyDiamond="(showForm = true), (priceSelected = 2)"
          v-if="!showForm"
        />
      </div>
      <ul class="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>

      <div class="lang-switch">
        <v-btn
          fab
          v-for="entry in languages"
          :key="entry.title"
          @click="changeLocale(entry.language)"
          style="margin-right: 10px;"
          :class="{ activeLang: currentLang === entry.language }"
        >
          <flag :iso="entry.flag" />
        </v-btn>
      </div>
      <!-- <button @click="submit">Pay now!</button> -->
    </v-main>
  </v-app>
</template>

<script>
import i18n from "@/plugins/i18n";
import BookingForm from "../components/booking-form";
import Prices from "../components/prices.vue";
export default {
  name: "App",

  components: {
    BookingForm,
    Prices,
  },
  data() {
    return {
      step: 1,
      paypal: {
        sandbox: "<sandbox client id>",
        production: "<production client id>",
      },
      loading: false,
      showForm: false,
      priceSelected: 0,
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "es", language: "es", title: "Español" },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
  },
  computed: {
    currentLang: function() {
      return i18n.locale;
    },
  },
};
</script>
<style scoped style="scss">
.logo {
  position: absolute;
  top: -5em;
  left: 1em;
}
.logo img {
  width: 300px;
}
@media only screen and (max-width: 970px) {
  .logo {
    width: 100%;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .title p {
    font-size: 30px !important;
  }
}

.wrapper {
  overflow: hidden;
  background: linear-gradient(-45deg, #ffffff73, #66a6ff, #f2767c, #a15054);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.form-wrapper {
  z-index: 99;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.title {
  text-align: center;
  z-index: 1;
}
.title p {
  font-size: 60px;
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  line-height: 1.37;
}

.step-one {
  background: linear-gradient(to right, #13547a, #80d0c7);
}
.step-two {
  background: linear-gradient(to right, #3f0000c2, #ff0000af);
}
.step-three {
  background: linear-gradient(to right, #024408, #09ff00);
}
.form {
  z-index: 99;
  margin: auto;
}
.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.507);
  bottom: -160px;

  -webkit-animation: square 25s infinite;
  animation: square 25s infinite;

  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

li:nth-child(1) {
  left: 10%;
}

li:nth-child(2) {
  left: 20%;

  width: 80px;
  height: 80px;

  animation-delay: 2s;
  animation-duration: 17s;
  background-color: #66a6ffce;
}

li:nth-child(3) {
  left: 25%;
  animation-delay: 4s;
  background: #89f7fe;
}

li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;

  animation-duration: 22s;

  background-color: white;
}

li:nth-child(5) {
  left: 70%;
}

li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  animation-delay: 3s;
  background-color: #66a6ffce;
}

li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;

  animation-delay: 7s;
}

li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;

  animation-delay: 15s;
  animation-duration: 40s;
  background: #f2767c;
}

li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;

  animation-delay: 2s;
  animation-duration: 40s;
  background-color: #f2767c;
}

li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  background: #f2767c;
  animation-delay: 11s;
}
@-webkit-keyframes square {
  0% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(-120vh) rotate(600deg);
  }
}
@keyframes square {
  0% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(-120vh) rotate(600deg);
  }
}

.lang-switch {
  z-index: 99;
  position: absolute;
  bottom: 0;
  padding: 15px;
}

.activeLang {
  border: lime 3px solid;
}
</style>

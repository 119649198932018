import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    info: "Info",
    level: "Level",
    pay: "Payment",
    stepOneMsg: "Sign Up For Your Course Today!",
    fullName: "Full Name",
    email: "Email",
    stepTwoMsg: "Which Course Would You Like?",
    beginner: "Beginner",
    intermediate: "Intermediate",
    advanced: "Advanced",
    child: "Child",
    adult: "Adult",
    stepThreeMsg: "How Would You Like To Pay?",
    month: "Month",
    courseFor: " course for ",
    or: "Or",
    continueButton: "Continue",
    backButton: "Back",
    payNow: "Pay Now",
    successMsg: "Congratulations!",
    allSet: "You are all set!",
    emailed: "We just Emailed you at ",
    schedule: " to schedule your course!",
    silver: "Silver Package",
    gold: "Gold Package",
    diamond: "Diamond Package",
    buyNow: "Buy Now",
    desc1: "24 Personalized Classes",
    desc1a: "48 Personalized Classes",
    desc1b: "72 Personalized Classes",

    desc2: "Flexible Schedule",
    desc3: "Monthly Master Class",
    desc4: "Professional Guide Pre, During and Post class",
    desc5: "Access to Learning Tools",
    desc6: "Daily English Tips",
    off: "off",
  },
  es: {
    info: "Info",
    level: "Nivel",
    pay: "Pago",
    stepOneMsg: "Inscribete en tu curso ahora!",
    fullName: "Nombre Completo",
    email: "Correo Electronico",
    stepTwoMsg: "Cual Curso Deseas?",
    beginner: "Principiante",
    intermediate: "Intermedio",
    advanced: "Avanzado",
    child: "Niño",
    adult: "Adulto",
    stepThreeMsg: "Como Quieres Pagar?",
    month: "Mes",
    courseFor: " curso para ",
    continueButton: "Continuar",
    or: "Or",
    backButton: "Atras",
    payNow: "Pagar Ahora",
    successMsg: "Felicidades!",
    allSet: "¡Estás listo!",
    emailed: "Le acabamos de enviar un correo electrónico a ",
    schedule: " para programar tu curso",
    silver: "Paquete Silver",
    gold: "Paquete Gold",
    diamond: "Paquete Diamond",
    buyNow: "COMPRA YA",
    desc1: "24 Clases Personalizadas",
    desc1a: "48 Clases Personalizadas",
    desc1b: "72 Clases Personalizadas",
    desc2: "Horarios Flexibles",
    desc3: "'Master Class' Mensual",
    desc4: "Guia Profesional Pre, Durante Y Post",
    desc5: "Acceso A Herraminetas de Aprendizaje",
    desc6: "Tips Pràcticos  Diariamente",
    off: "Descuento",
  },
};

const i18n = new VueI18n({
  locale: "es", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;

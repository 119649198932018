<template>
  <v-stepper v-model="e1" class="form" height="100%">
    <stripe-checkout
      ref="checkoutRef"
      :mode="'payment'"
      :customerEmail="userEmail"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />

    <v-stepper-items class="form-items" height="100%">
      <v-stepper-content step="1">
        <v-card class="mb-12 card" height="60%"
          ><v-card-text>
            <v-text-field
              ref="name"
              v-model="userName"
              :rules="[() => !!userName || 'Whats\'s your name?']"
              :error-messages="errorMessages"
              :label="$t('fullName')"
              placeholder="John Doe"
              required
            ></v-text-field>
            <v-text-field
              ref="email"
              v-model="userEmail"
              :rules="[rules.required, rules.email]"
              :label="$t('email')"
              placeholder="learner@gmail.com"
              required
            ></v-text-field>
            <v-text-field
              ref="Whatsapp"
              v-model="userWhatsapp"
              :rules="[rules.required]"
              label="Whatsapp Number"
              placeholder="+505 0000 0000"
              required
            ></v-text-field>
          </v-card-text>
        </v-card>
        <div style="display:flex; justify-content: flex-end;">
          <v-btn
            color="primary"
            :disabled="userName === '' || userEmail === ''"
            @click="submit()"
          >
            {{ $t("payNow") }}
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  components: {
    StripeCheckout,
  },
  props: {
    priceSelected: Number,
  },
  data() {
    this.publishableKey = "pk_live_Bhzx5ObcnKQh45EaC0uTYkcC00VqrQEsWz";
    return {
      silver: [
        {
          price: "price_1OrAxxDRhNkSwLEqx7gUXkAg",
          quantity: 1,
        },
      ],
      gold: [
        {
          price: "price_1OrAz1DRhNkSwLEqT7b7vofH",
          quantity: 1,
        },
      ],
      diamond: [
        {
          price: "price_1OrB08DRhNkSwLEq4PD3JOyZ",
          quantity: 1,
        },
      ],
      successURL: "https://book.gospeakacademy.com/success",
      cancelURL: "https://book.gospeakacademy.com",
      e1: 1,
      userEmail: "",
      userName: "",
      userWhatsapp: "",
      selectedItem: 0,
      isAdult: true,
      plan: 0,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "We need a real email";
        },
      },
    };
  },
  computed: {
    items() {
      return [
        { text: this.$t("beginner"), icon: "mdi-baby-carriage" },
        { text: this.$t("intermediate"), icon: "mdi-book-open-variant" },
        { text: this.$t("advanced"), icon: "mdi-brain" },
      ];
    },
    level() {
      switch (this.selectedItem) {
        case 0: {
          return this.items[0].text;
        }
        case 1: {
          return this.items[1].text;
        }
        case 2: {
          return this.items[2].text;
        }
      }

      return "beginner";
    },
    totalPrice() {
      if (this.isAdult) {
        return 120;
      } else {
        return 100;
      }
    },
    monthlyPrice() {
      return this.totalPrice / 4;
    },
    lineItems() {
      switch (this.priceSelected) {
        case 0:
          return this.silver;
        case 1:
          return this.gold;
        case 2:
          return this.diamond;
        default:
          return 0;
      }
    },
  },
  methods: {
    submit() {
      console.log(this.lineItems);
      let userData = {
        name: this.userName,
        email: this.userEmail,
        whatsapp: this.userWhatsapp,
        package: this.priceSelected,
      };
      userData = JSON.stringify(userData);

      sessionStorage.setItem("userData", userData);
      this.$refs.checkoutRef.redirectToCheckout();
    },
    changeStep(step) {
      this.e1 = step;
      this.$emit("stepChanged", step);
    },
  },
};
</script>

<style>
.planActive {
  background: green !important;
  color: white !important;
  font-size: 20px !important;
}
.age {
  display: flex;
  justify-content: space-around;
  color: grey;
  font-weight: bold;
}
.ageChose {
  color: #ff0000af;
  font-size: 40px;
  padding: 5px;
  border-radius: 20px;
}
.card {
  background-color: rgba(255, 255, 255, 0.418) !important;
}
.form {
  max-width: 500px;
  max-height: 600px;
  background-color: #ffffff73 !important;
}
.payment-option {
  display: flex;
  justify-content: space-around;
}
.v-item-active {
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, 0.2);
}
.form-header {
  background-color: #ffffff73;
}

.form-items {
  height: 100%;
}
</style>
